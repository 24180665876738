.tour{
    height: 1100px;
    width: 100%
}
.tour-holder{
    position: relative;
    width: inherit;
    height: inherit;
    overflow: hidden;
    background-image: url("../../resources/images/IMG_7816__four.jpg");
    background-size: cover;
}

.tour-holder>img{
    width: 100%;
    transform: scale(1.2);
}
/* .Tour-holder>.img{
    width: 100%;
    transform: scale(1.2);
    background-image: url("../../resources/images/Tour.jpg");
} */

.tour-cover{
    height: inherit;
    position: absolute;
    width: inherit;
    height: 100%;
    top: 0;
    background: rgba(11,11,12,0.43);
    background:#0b0b0cc2 100%; 
    background: linear-gradient(to bottom,rgba(11,11,12,0.43) 0%,#0b0b0c 100%);
    opacity: 1;
} 
.gradianet:hover{
    /* background: linear-gradient(-35deg, rgb(0, 139, 98), rgb(0, 119, 109)); */
    background-color: rgba(26, 44, 52, 0.86);
}
.tour-texts{
    text-align: center;
     padding: 8% 0 25% 0; 
    /* padding-top: calc(100vw * 0.12);
    padding-bottom: calc(100vw * 0.12); */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.tour-texts>p{
    font-size: 40px;
    font-family: "dosis";
    font-weight: 100;
    color: white;
}
.tour-texts>p:first-of-type{
    font-size: 18px;
    font-family: "dosis";
    font-weight: 100;
    color: white;
    margin: 7px 0;
    font-weight: 400;
}

.round-btn{
    margin: 40px auto;
    width: 100px;
    height: 100px;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.3s;
    border: 7px solid rgb(235, 235, 235);
    background-color: rgba(7, 156, 255, 0.123);
}

.round-btn>i{
    color: rgb(238, 238, 238);
    font-size: 30px;
    transition: 0.3s;
    margin-left: 4px;

}

.round-btn:hover>i{
    color: rgb(255, 255, 255);
    transform: scale(1.3)
}
.round-btn:hover{
    color: rgb(255, 255, 255);
    border: 5px solid rgba(235, 235, 235, 0.109);
    transform: scale(1.1);
    /* border: 7px solid rgb(255, 255, 255); */
}


@media only screen and (max-width: 720px) {
    .tour{
        height: 400px;
    }

}