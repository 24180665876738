.nav{
    width: 100%;
    height: 50px;
    background-color: rgba(0,0,0,0.95);
    position: fixed;
    z-index: 2;
    transition: all 0.3s;
}
.nav-holder{
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
}
.logo{
font-size: 35px;
color: white;
margin-left: 20px;
}
  
.menu{
    height: 46.5px;
}
.menu>ul{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 11.5px 0;
    padding: 0;
    font-size: 14px;
    font-family: 'Menlo';
    font-weight: 600;
    color: rgba(255, 255, 255, 0.356);
}
.menu>ul>li{
margin: 0 21px;
text-align: center;
padding: 3px 0;
transition: 0.2s all;
cursor: pointer;
position: relative;
}
.socials{
    margin-right: 40px;
    display: flex;
}
.icon-frame{
margin: 0 5px;
display: flex;
height: 21px;
overflow: hidden;
transform: translateY(0px);
cursor: pointer;
}
.icon-frame>div{
    display: flex;
    flex-direction: column;
    height: 21px;
    transform: translateY(0px);
    transition: all .3s;
    }
.socials i{
    background-color: transparent;
    width: 22px;
    color: rgb(255, 255, 255);
    cursor: pointer;
}
.icon-frame>div:hover{
    transform: translateY(-20px);
}
.myBlue{
    color: rgba(89, 0, 255, 0.74)!important;
}
/* ///////////// */
.static{
    position: fixed;
    background-color: rgba(0, 0, 0, 0);
    height: 80px;
}
.fixed{
    position: fixed;
    background-color: rgba(0,0,0,0.95);
    height: 50px;

}
/* ///////////////// */
.to-top-i{
    position: fixed;
    bottom: 30px;
    right: 30px;
    display: inline-block;
    padding: 12px 10px 5px 10px;
    border-radius: 50px;
    background-color: rgba(213, 245, 255, 0.027);
    z-index: 2;
    color: rgb(235, 235, 235);
    width: 50px;
    height: 50px;
    text-align: center;
    transition: all 0.4s;
    cursor: pointer;
}
.nav-pop{
    border-bottom: 2px solid rgb(255, 123, 0);
    width: 100%;
    height: 100%;
    position: absolute;
    transition: 0.2s all;
    position: absolute;
    transform: scaleX(0)
}
.menu>ul>:hover> .nav-pop{
    transform: scaleX(1);
    border-color: rgba(89, 0, 255, 0.74);
}
.navBorderBottom{
    transform: scaleX(1);
    border-color: rgba(89, 0, 255, 0.74);
    border-radius: 5px;
}
.small-menu{
    display: NONE;
    position: fixed;
    left: 0;
    /* top: 0; */
    width: 30%;
    height: 100%;
    background-color:rgba(0, 0, 0, 0.438);
    z-index: 2;
}
.smStatic{
    background-color:rgba(0, 0, 0, 0.151);
}
.smDynamic{
    background-color:rgba(0, 255, 128, 0.438);
}
.small-menu>ul{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    text-align: center;
    height: 100%;
    padding: 20px 0;
}
.small-menu>ul>li{
    color: white;
    height: 100%;
    font-size: 15px;

    }
    .small-menu>ul>li>span{
height: 100%;
width: 100%;
    
        }
/* /////////////////////////////////////////////////////////////////  responsive ////////////// */
@media only screen and (max-width: 720px) {
    .menu{
        display: none;
    }
    .socials{
        display: none;
    }
    .small-menu{
        display: block;
    }
}