body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif, "Menlo Regular";
  src: local('Menlo Regular'), url(/static/media/Menlo-Regular.b01d11a9.woff) format('truetype');

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face{
    font-family:"Hi";
    src: url(/static/media/hi.1c37ab07.woff2);
}
@font-face{
    font-family:"thin";
    src: url(/static/media/thin.43b15547.woff2);
}
@font-face{
    font-family:"bbc";
    src: url(/static/media/bbcThin-normal.2735a3a6.woff2);
    font-weight: normal;
}
@font-face{
    font-family:"bbc";
    src: url(/static/media/bbcThin.479970ff.woff2);
    font-weight: lighter;
}
@font-face{
    font-family:"bbc";
    src: url(/static/media/bbcThin-bold.9b3766ef.woff2);
    font-weight: bold;
}
@font-face{
    font-family:"dosis";
    src: url(/static/media/Dosis-Light.582ed43b.ttf);
    font-weight: lighter;
}
@font-face{
    font-family:"dosis";
    src: url(/static/media/Dosis-Medium.5c560d58.ttf);
    font-weight: 500;
    }
@font-face{
    font-family:"dosis";
    src: url(/static/media/Dosis-SemiBold.f1dd69c4.ttf);
    font-weight: 300;
}
@font-face{
    font-family:"dosis";
    src: url(/static/media/Dosis-Regular.117498ee.ttf);
    font-weight: 400;
    }
@font-face{
    font-family:"dosis";
    src: url(/static/media/Dosis-ExtraBold.fa1a95c3.ttf);
    font-weight: bolder;
}
@font-face{
    font-family:"dosis";
    src: url(/static/media/Dosis-ExtraLight.751fccd2.ttf);
    font-weight: 100;
}

*{
    scroll-behavior: smooth;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    --myBlue:rgb(7, 156, 255);
}
#root{
    position: relative;
}
.App{
    position:relative;
}
a{
    text-decoration: none;
}
ul{
    list-style-type: none;
}
@font-face {
  font-family: 'fontello';
  src: url(/static/media/fontello.7f6f9aad.eot);
  src: url(/static/media/fontello.7f6f9aad.eot#iefix) format('embedded-opentype'),
       url(/static/media/fontello.236a75db.woff2) format('woff2'),
       url(/static/media/fontello.85d2acca.woff) format('woff'),
       url(/static/media/fontello.c43501a0.ttf) format('truetype'),
       url(/static/media/fontello.195eb76a.svg#fontello) format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'fontello';
    src: url('../font/fontello.svg?51733413#fontello') format('svg');
  }
}
*/
 
 [class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: none;
 
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */
 
  /* For safety - reset parent styles, that can break glyph codes*/
  -webkit-font-feature-settings: normal;
          font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
 
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
 
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;
 
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
 
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
 
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}
 
.icon-calendar:before { content: '\E800'; } /* '' */
.icon-search:before { content: '\E801'; } /* '' */
.icon-heart:before { content: '\E802'; } /* '' */
.icon-thumbs-up:before { content: '\E803'; } /* '' */
.icon-clock:before { content: '\E804'; } /* '' */
.icon-up-open:before { content: '\E805'; } /* '' */
.icon-right-open:before { content: '\E807'; } /* '' */
.icon-user-outline:before { content: '\E808'; } /* '' */
.icon-basket:before { content: '\E809'; } /* '' */
.icon-logo:before { content: '\E80A'; } /* '' */
.icon-left-open:before { content: '\E80B'; } /* '' */
.icon-down-open:before { content: '\E80C'; } /* '' */
.icon-basket-1:before { content: '\E80D'; } /* '' */
.icon-play:before { content: '\E80E'; } /* '' */
.icon-play-outline:before { content: '\E80F'; } /* '' */
.icon-stop-outline:before { content: '\E810'; } /* '' */
.icon-stop:before { content: '\E811'; } /* '' */
.icon-pause-outline:before { content: '\E812'; } /* '' */
.icon-pause:before { content: '\E813'; } /* '' */
.icon-fast-fw-outline:before { content: '\E814'; } /* '' */
.icon-fast-fw:before { content: '\E815'; } /* '' */
.icon-rewind-outline:before { content: '\E816'; } /* '' */
.icon-rewind:before { content: '\E817'; } /* '' */
.icon-record-outline:before { content: '\E818'; } /* '' */
.icon-record:before { content: '\E819'; } /* '' */
.icon-eject-alt-outline:before { content: '\E81A'; } /* '' */
.icon-eject-alt:before { content: '\E81B'; } /* '' */
.icon-waves:before { content: '\E81C'; } /* '' */
.icon-heart-1:before { content: '\E81D'; } /* '' */
.icon-heart-empty:before { content: '\E81E'; } /* '' */
.icon-heart-2:before { content: '\E81F'; } /* '' */
.icon-comment:before { content: '\E820'; } /* '' */
.icon-comment-alt2:before { content: '\E821'; } /* '' */
.icon-comment-1:before { content: '\E822'; } /* '' */
.icon-user:before { content: '\E823'; } /* '' */
.icon-shop:before { content: '\E835'; } /* '' */
.icon-export:before { content: '\F081'; } /* '' */
.icon-comment-empty:before { content: '\F0E5'; } /* '' */
.icon-instagram-1:before { content: '\F16D'; } /* '' */
.icon-soundcloud:before { content: '\F1BE'; } /* '' */
.icon-copyright:before { content: '\F1F9'; } /* '' */
.icon-vimeo:before { content: '\F27D'; } /* '' */
.icon-telegram:before { content: '\F2C6'; } /* '' */
.icon-twitter:before { content: '\F309'; } /* '' */
.icon-facebook:before { content: '\F30C'; } /* '' */
.icon-gplus:before { content: '\F30F'; } /* '' */
.icon-instagram-2:before { content: '\F32D'; } /* '' */
.nav{
    width: 100%;
    height: 50px;
    background-color: rgba(0,0,0,0.95);
    position: fixed;
    z-index: 2;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}
.nav-holder{
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
}
.logo{
font-size: 35px;
color: white;
margin-left: 20px;
}
  
.menu{
    height: 46.5px;
}
.menu>ul{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 11.5px 0;
    padding: 0;
    font-size: 14px;
    font-family: 'Menlo';
    font-weight: 600;
    color: rgba(255, 255, 255, 0.356);
}
.menu>ul>li{
margin: 0 21px;
text-align: center;
padding: 3px 0;
-webkit-transition: 0.2s all;
transition: 0.2s all;
cursor: pointer;
position: relative;
}
.socials{
    margin-right: 40px;
    display: flex;
}
.icon-frame{
margin: 0 5px;
display: flex;
height: 21px;
overflow: hidden;
-webkit-transform: translateY(0px);
        transform: translateY(0px);
cursor: pointer;
}
.icon-frame>div{
    display: flex;
    flex-direction: column;
    height: 21px;
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
    -webkit-transition: all .3s;
    transition: all .3s;
    }
.socials i{
    background-color: transparent;
    width: 22px;
    color: rgb(255, 255, 255);
    cursor: pointer;
}
.icon-frame>div:hover{
    -webkit-transform: translateY(-20px);
            transform: translateY(-20px);
}
.myBlue{
    color: rgba(89, 0, 255, 0.74)!important;
}
/* ///////////// */
.static{
    position: fixed;
    background-color: rgba(0, 0, 0, 0);
    height: 80px;
}
.fixed{
    position: fixed;
    background-color: rgba(0,0,0,0.95);
    height: 50px;

}
/* ///////////////// */
.to-top-i{
    position: fixed;
    bottom: 30px;
    right: 30px;
    display: inline-block;
    padding: 12px 10px 5px 10px;
    border-radius: 50px;
    background-color: rgba(213, 245, 255, 0.027);
    z-index: 2;
    color: rgb(235, 235, 235);
    width: 50px;
    height: 50px;
    text-align: center;
    -webkit-transition: all 0.4s;
    transition: all 0.4s;
    cursor: pointer;
}
.nav-pop{
    border-bottom: 2px solid rgb(255, 123, 0);
    width: 100%;
    height: 100%;
    position: absolute;
    -webkit-transition: 0.2s all;
    transition: 0.2s all;
    position: absolute;
    -webkit-transform: scaleX(0);
            transform: scaleX(0)
}
.menu>ul>:hover> .nav-pop{
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
    border-color: rgba(89, 0, 255, 0.74);
}
.navBorderBottom{
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
    border-color: rgba(89, 0, 255, 0.74);
    border-radius: 5px;
}
.small-menu{
    display: NONE;
    position: fixed;
    left: 0;
    /* top: 0; */
    width: 30%;
    height: 100%;
    background-color:rgba(0, 0, 0, 0.438);
    z-index: 2;
}
.smStatic{
    background-color:rgba(0, 0, 0, 0.151);
}
.smDynamic{
    background-color:rgba(0, 255, 128, 0.438);
}
.small-menu>ul{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    text-align: center;
    height: 100%;
    padding: 20px 0;
}
.small-menu>ul>li{
    color: white;
    height: 100%;
    font-size: 15px;

    }
    .small-menu>ul>li>span{
height: 100%;
width: 100%;
    
        }
/* /////////////////////////////////////////////////////////////////  responsive ////////////// */
@media only screen and (max-width: 720px) {
    .menu{
        display: none;
    }
    .socials{
        display: none;
    }
    .small-menu{
        display: block;
    }
}
.banner{
    /* height: 800px; */
    width: 100%
}
.banner-image{
display: unset;
}
/* .banner{
background: rgba(11,11,12,0.43);
background: linear-gradient(to bottom,rgba(11,11,12,0.43) 0%,#0b0b0c 100%);
opacity: 1;
} */
.banner-holder{
    height: 100%;
    position: relative;
    width: inherit;
    height: inherit;
    overflow: hidden;
    /* background-image: url("../../resources/images/IMG_7782__one.jpeg"); */
    background-size:cover;
}
.banner-holder>img{
    width: 100%;
}
.banner-cover{
    height: inherit;
    position: absolute;
    width: inherit;
    height: 100%;
    top: 0;
    background: rgba(11,11,12,0.43);
    background: -webkit-gradient(linear,left top, left bottom,from(rgba(11,11,12,0.43)),to(#0b0b0c));
    background: -webkit-linear-gradient(top,rgba(11,11,12,0.43) 0%,#0b0b0c 100%);
    background: linear-gradient(to bottom,rgba(11,11,12,0.43) 0%,#0b0b0c 100%);
    opacity: 1;
}
.banner-texts{
    margin: auto;
    /* width: 600px; */
    text-align: center;
    line-height: 40px;
    padding: 9% 0 25% 0;
}
.banner-texts>p:first-of-type{
    font-weight: 100;
font-family: "Menlo";
font-size: 110px;
color: rgba(255, 255, 255, 0.329);
}
.banner-texts>p:last-of-type{
font-family:  "Menlo";
font-size: 17px;
color: rgba(206, 206, 206, 0.774);
font-weight: 500;
margin: 10px 0;
}
.btn-midd{
margin: 150px auto;
width: 300px;
height: 60px;
border: 2px solid var(--myBlue);
border-radius: 30px;
display: flex;
justify-content: center;
align-items: center;
/* background: linear-gradient(-35deg, rgb(255, 0, 235), rgba(1, 173, 100, 0.79), rgb(245, 147, 0),rgb(255, 0, 0)); */
-webkit-transition: 0.3s;
transition: 0.3s;
}

/* .gradianet{
    background: rgba(0, 0, 0, 0.92);
    width: 295px;
    height: 56px;
    transition: 0.3s;
} */


.gradianet:hover{
    /* background: linear-gradient(-35deg, rgb(0, 139, 98), rgb(0, 119, 109)); */
    background-color: rgba(26, 44, 52, 0.86);
}
.btn-midd>p{
color: white;
font-family: "Hi";
font-weight: 500;
}

.btn-midd>i{
    color: white;
    margin-left: 0px;
    -webkit-transition: 0.3s;
    transition: 0.3s;
    color: rgba(75, 0, 0, 0);
}
.btn-midd:hover > .btn-midd i{
    margin-left: 20px;
    color: rgb(255, 255, 255);
}

.theStory-holder{
    height: 600px;
    background-color: rgb(11, 11, 12);
    padding: 15% 0;
}
.theStory-textframe{
    margin: auto;
    text-align: center;
    line-height: 40px;
}
.theStory-textframe>h4{
color: var(--myBlue);
}
.theStory-textframe>p:first-of-type{
    color: rgb(255, 255, 255);
    font-size: 35px;
    font-family: "Menlo";
    font-weight: 500;
    word-spacing: 8px;
    margin-top: 20px;
}
.theStory-textframe>p:last-of-type{
    color: rgba(190, 190, 190, 0.77);
    font-size: 22px;
    font-family: "Menlo";
    font-weight: 300;
    line-height: 30px;
    margin-top: 20px;
}

/* ////////////////////////////////////////////////////////////////// */
@media only screen and (max-width: 720px) {
    /* .banner{
        height: 500px;
    } */
    .banner-holder{
        background-image: url(/static/media/IMG_7782__one.aabcd4f3.jpeg);
        background-size: cover;
        height: 500px;
    }
    .banner-image{
        display: none;
    }
    .banner-texts>p:first-of-type {
        font-size: 100px;
    }
    .banner-texts {
        padding: 30% 0 25% 0;
    }
    .time-table-self>:nth-child(2){
    display: none;
    }
    .time-table-self>:last-child{
        width: 100%!important;
        }
}
.last-album{
    height: 1100px;
    width: 100%
}
.last-album-holder{
    background-color: #0b0b0c 100%;
    position: absolute;
    width: inherit;
    height: 1100px;
    overflow: hidden;
    background-image:url(/static/media/IMG_7808__two.42f59b78.jpg);
    
    background-size: auto;
    background-repeat: no-repeat;
    
}
/* .last-album-holder>img{
    width: 100%;
    transform: scale(1.2);
} */
.last-album-cover{
    height: inherit;
    position: absolute;
    width: inherit;
    height: 100%;
    top: 0;
    background: rgba(11,11,12,0.43);
    background:#0b0b0c 100%;
    opacity: 1;
}
.last-album-texts{
    text-align: center;
    padding: 8% 0 25% 0; 
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.last-album-texts>p{
    font-size: 40px;
    font-family: "dosis";
    font-weight: 100;
    color: white;
    margin: 40px;
}
.player{
    width: 90%;
    max-width: 1000px;

    height: 400px;
    background-color: rgb(202, 202, 202);
    margin-bottom: 50px;
}
.btn{
 margin: 40px auto;
margin: 40px auto;
width: 300px;
height: 60px;
border-radius: 30px;
display: flex;
justify-content: center;
align-items: center;
-webkit-transition: 0.3s;
transition: 0.3s;
border: 2px solid var(--myBlue);
background-color: rgba(7, 156, 255, 0.123);
}
.btn>p{
    color: white;
    font-size: 15px;
    font-family: "Hi";
    font-weight: 600;
}
.btn>i{
    color: rgba(255, 255, 255, 0);
    font-size: 1px;
    font-family: "Hi";
    font-weight: 600;
    margin-left: 0px;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}
.btn:hover{
    background-color: rgb(7, 156, 255);
}
.btn:hover>i{
    color: rgb(255, 255, 255);
    font-size: 15px;
    font-family: "Hi";
    margin-left: 20px;
}
@media only screen and (max-width: 720px) {
    .last-album {
        height: 800px
    }
}
.tour{
    height: 1100px;
    width: 100%
}
.tour-holder{
    position: relative;
    width: inherit;
    height: inherit;
    overflow: hidden;
    background-image: url(/static/media/IMG_7816__four.88405bd8.jpg);
    background-size: cover;
}

.tour-holder>img{
    width: 100%;
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
}
/* .Tour-holder>.img{
    width: 100%;
    transform: scale(1.2);
    background-image: url("../../resources/images/Tour.jpg");
} */

.tour-cover{
    height: inherit;
    position: absolute;
    width: inherit;
    height: 100%;
    top: 0;
    background: rgba(11,11,12,0.43);
    background:#0b0b0cc2 100%; 
    background: -webkit-gradient(linear,left top, left bottom,from(rgba(11,11,12,0.43)),to(#0b0b0c)); 
    background: -webkit-linear-gradient(top,rgba(11,11,12,0.43) 0%,#0b0b0c 100%); 
    background: linear-gradient(to bottom,rgba(11,11,12,0.43) 0%,#0b0b0c 100%);
    opacity: 1;
} 
.gradianet:hover{
    /* background: linear-gradient(-35deg, rgb(0, 139, 98), rgb(0, 119, 109)); */
    background-color: rgba(26, 44, 52, 0.86);
}
.tour-texts{
    text-align: center;
     padding: 8% 0 25% 0; 
    /* padding-top: calc(100vw * 0.12);
    padding-bottom: calc(100vw * 0.12); */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.tour-texts>p{
    font-size: 40px;
    font-family: "dosis";
    font-weight: 100;
    color: white;
}
.tour-texts>p:first-of-type{
    font-size: 18px;
    font-family: "dosis";
    font-weight: 100;
    color: white;
    margin: 7px 0;
    font-weight: 400;
}

.round-btn{
    margin: 40px auto;
    width: 100px;
    height: 100px;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    -webkit-transition: 0.3s;
    transition: 0.3s;
    border: 7px solid rgb(235, 235, 235);
    background-color: rgba(7, 156, 255, 0.123);
}

.round-btn>i{
    color: rgb(238, 238, 238);
    font-size: 30px;
    -webkit-transition: 0.3s;
    transition: 0.3s;
    margin-left: 4px;

}

.round-btn:hover>i{
    color: rgb(255, 255, 255);
    -webkit-transform: scale(1.3);
            transform: scale(1.3)
}
.round-btn:hover{
    color: rgb(255, 255, 255);
    border: 5px solid rgba(235, 235, 235, 0.109);
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    /* border: 7px solid rgb(255, 255, 255); */
}


@media only screen and (max-width: 720px) {
    .tour{
        height: 400px;
    }

}
.live{
    height: 1100px;
    width: 100%
}
.live-holder{
    position: relative;
    width: inherit;
    height: inherit;
    overflow: hidden;
    background-image: url(/static/media/IMG_7808__two.42f59b78.jpg);
    background-size: cover;
}

.live-holder>img{
    width: 100%;
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
}
/* .live-holder>.img{
    width: 100%;
    transform: scale(1.2);
    background-image: url("../../resources/images/Live.jpg");
} */

.live-cover{
    height: inherit;
    position: absolute;
    width: inherit;
    height: 100%;
    top: 0;
    background: rgba(11,11,12,0.43);
    background:#0b0b0cc2 100%; 
    background: -webkit-gradient(linear,left top, left bottom,from(rgba(11,11,12,0.43)),to(#0b0b0c)); 
    background: -webkit-linear-gradient(top,rgba(11,11,12,0.43) 0%,#0b0b0c 100%); 
    background: linear-gradient(to bottom,rgba(11,11,12,0.43) 0%,#0b0b0c 100%);
    opacity: 1;
} 
.gradianet:hover{
    /* background: linear-gradient(-35deg, rgb(0, 139, 98), rgb(0, 119, 109)); */
    background-color: rgba(26, 44, 52, 0.86);
}
.live-texts{
    text-align: center;
     padding: 8% 0 25% 0; 
    /* padding-top: calc(100vw * 0.12);
    padding-bottom: calc(100vw * 0.12); */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.live-texts>p{
    font-size: 40px;
    font-family: "dosis";
    font-weight: 100;
    color: white;
}
.live-texts>p:first-of-type{
    font-size: 18px;
    font-family: "dosis";
    font-weight: 100;
    color: white;
    margin: 7px 0;
    font-weight: 400;
}

.round-btn{
    margin: 40px auto;
    width: 100px;
    height: 100px;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    -webkit-transition: 0.3s;
    transition: 0.3s;
    border: 7px solid rgb(235, 235, 235);
    background-color: rgba(7, 156, 255, 0.123);
}

.round-btn>i{
    color: rgb(238, 238, 238);
    font-size: 30px;
    -webkit-transition: 0.3s;
    transition: 0.3s;
    margin-left: 4px;

}

.round-btn:hover>i{
    color: rgb(255, 255, 255);
    -webkit-transform: scale(1.3);
            transform: scale(1.3)
}
.round-btn:hover{
    color: rgb(255, 255, 255);
    border: 5px solid rgba(235, 235, 235, 0.109);
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    /* border: 7px solid rgb(255, 255, 255); */
}


@media only screen and (max-width: 720px) {
    .live{
        height: 400px;
    }

}
.Blog{
    height: 1100px;
    width: 100%
}
.Blog-holder{
    position: relative;
    width: inherit;
    height: inherit;
    overflow: hidden;
}
.Blog-holder>img{
    width: 100%;
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
}
.Blog-cover{
    height: inherit;
    position: absolute;
    width: inherit;
    height: 100%;
    top: 0;
    background: #0a0a0a;;
    opacity: 1;
}
.Blog-texts{
    text-align: center;
     padding: 8% 0 25% 0; 
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.Blog-texts>p{
    font-size: 40px;
    font-family: "dosis";
    font-weight: 100;
    color: white;
    margin-bottom: 20px;
}


.round-btn:hover>i{
    color: rgb(255, 255, 255);
    -webkit-transform: scale(1.3);
            transform: scale(1.3)
}
.round-btn:hover{
    color: rgb(255, 255, 255);
    border: 5px solid rgba(235, 235, 235, 0.109);
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    /* border: 7px solid rgb(255, 255, 255); */
}
.data{
width: 100%;
max-width: 1100px;
display: flex;
justify-content: space-between;
align-items: center;
}
.data-frame{
    width: 370px;
    height: 600px;
    margin: 0 15px;
    position: relative;
    overflow: hidden;
    border-radius: 7px;
    overflow: hidden;
    cursor: pointer;
}

.frame-cover{
    background-color: rgba(0, 0, 0, 0.43);
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;;
    left: 0;
    z-index: 1;
    
}
.data-frame>img{
    position: absolute;
    height: 100%;
    top: 0;
    left: 0;
    -webkit-transform: scale(1.1) translateX(-30px);
            transform: scale(1.1) translateX(-30px);
    -webkit-transition: 0.4s;
    transition: 0.4s;
}
.data-frame:hover > .aaa{
    -webkit-transform: scale(1.15) translateX(-30px);
            transform: scale(1.15) translateX(-30px);
}
.frame-content{
    padding: 0 20px;
    color: white;
}
.frame-heading{
    text-align: left;
}
.frame-heading>p{
    margin-top: 20px;
    border: 2px solid gray;
    font-size: 16px;
    display: inline-block;
    border-radius: 30px;
    width: 70px;
    text-align: center;
    cursor: pointer;
    -webkit-transition: 0.3s;
    transition: 0.3s;
}
.frame-heading>p:hover{
background-color: var(--myBlue);
    border: 2px solid var(--myBlue);
}
.frame-body{
    text-align: left;
    padding: 120px 30px 0;
    line-height: 28px;
}
.frame-body>p:first-of-type{
    font-size: 15px;
    margin-bottom: 7px;

}
.frame-body>p:nth-child(2){
    font-size: 40px;
    font-family: "Dosis";
    font-weight: 200;
    line-height: 45px;
}
.frame-body>p:nth-child(3){
    font-size: 18px;
    font-family: "Dosis";
    font-weight: 500;
    line-height: 30px;
    margin-top: 17px;
}
.frame-info{
    display: flex;
    position: absolute;
    bottom:0;
    left: 0;
    width: 100%;
    padding: 11px 20px;
    font-size: 13px;
    border-top: 0.8px solid rgba(236, 236, 236, 0.267);
}
.frame-info>.frame-info_sec{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: rgba(231, 231, 231, 0.39);
    margin-right: 16px;
    cursor: pointer;
}
.frame-info>.frame-info_sec>p{
    color: rgba(250, 250, 250, 0.945);  
}
.frame-icon{
    color: rgba(226, 226, 226, 0.233);
    -webkit-transition: 0.3s;
    transition: 0.3s;
}
.frame-info_sec:hover>.frame-icon{
    color: var(--myBlue);  
}
.tour-btn{
    margin-top: 80px;
}
.Foot{
    position: relative;
}
.Footer{
    margin-bottom: 200px;
    background-color: #06060600;
}
.Footer-real{
    position: fixed;
    bottom: 0;
    z-index: -1;
    height: 200px;
    width: 100%;
    background-color: #060606;
    display: flex;
    justify-content: center;
    align-items: center;
}
.Footer-holder{
    height: 100%;
    padding: 40px 0;

}

.Footer-center{
    margin: auto;
}
.Footer-icons{
    display: flex;
    justify-content: center;
align-items: center;
}
.Footer-icons>i{
    font-size: 20px;
    padding: 10px;
    color: #ffffffbf;
    display: inline-block;
    border-radius: 30px;
    border: 2px solid rgba(121, 121, 121, 0.47);
    margin: 0 10px;
    cursor: pointer;
    -webkit-transition:all 0.2s;
    transition:all 0.2s;
}
.footer-copywrite{
    margin-top: 20px;
    font-size: 14px;
    color: rgba(240, 240, 240, 0.58);
    text-align: center;
}
.Footer-icons>i:hover{
    font-size: 20px;
    padding: 10px;
    color: #ffffffbf;

    border: 2px solid rgb(255, 255, 255);

}
